import $ from 'jquery';

$(document).ready(function () {
  $('.full-width-slider').slick({
    fade: true,
    cssEase: 'linear',
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    rows: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          adaptiveHeight: true,
        },
      },
    ],
  });
});
