import $ from 'jquery';

$(document).ready(function () {
  $('.small-images-slider').each(function () {
    const $slider = $(this);
    const autoplay = $slider.data('autoplay') === 'yes';

    $slider.slick({
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: autoplay,
      autoplaySpeed: 5000,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: false,
      rows: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  });
});
