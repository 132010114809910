import $ from 'jquery';

$(document).ready(function () {
  /* eslint-disable no-undef */
  particlesJS('particles-js', {
    particles: {
      number: {
        value: 700,
        density: {
          enable: true,
          // value_area: 631.3280775270874,
        },
      },
      color: {
        value: '#2ec3c3',
      },
      shape: {
        type: 'circle',
        stroke: {
          width: 0,
          color: '#ffffff',
        },
        // polygon: {
        //   nb_sides: 10,
        // },
      },
      opacity: {
        value: 0.75,
        random: true,
        anim: {
          enable: false,
          speed: 1,
          opacity_min: 0.1,
          sync: false,
        },
      },
      size: {
        value: 12,
        random: true,
        anim: {
          enable: false,
          speed: 40,
          size_min: 0.1,
          sync: false,
        },
      },
      line_linked: {
        enable: false,
        distance: 100,
        color: '#ffffff',
        opacity: 1,
        width: 20,
      },
      move: {
        enable: true,
        speed: 2,
        direction: 'random',
        random: true,
        straight: false,
        out_mode: 'out',
        bounce: false,
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200,
        },
      },
    },
    retina_detect: true,
  });
});
